
.sidebar-wrapper {
  transition: width 0.3s;
}

.sidebar-header {
  height: 64px;
  display: flex;
  background-color: #eab308;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  color: rgb(255, 255, 255);
}
.css-16jesut >.ps-menu-button:hover {
  background-color: #3469a1 !important;
}

.rotate {
  transition: transform 0.5s ease-in-out;
  transform: rotate(360deg);
}

::-webkit-scrollbar {
  width: 8px; 
}


::-webkit-scrollbar-track {
  background-color: #afafaf; /* Change track color */
}


::-webkit-scrollbar-thumb {
  background-color: #fff600; /* Change thumb color */
  border-radius: 8px; /* Adjust border-radius for a rounded thumb */
}

/* Scrollbar Thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #233459; /* Change thumb color on hover */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.mytable th,
.mytable td,
.mytable tr {
  border: 1px solid black;
}

.background-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../public/loginback.jpg') no-repeat center center fixed;
  background-size: cover;
}

.cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 20px;
  background-color: rgba(32, 4, 4, 0.486);
  border-radius: 10px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
}

.logo {
    margin-bottom: 20px;
}

.cont img {
    width: 100px;
    display: block;
    margin: 0 auto;
}